import AjaxManager from '../ajaxManager';
import UserReview from '@web/web-components/user-review/src/userReview';
import LazyLoadManager from '../lazyLoadManager';
import * as Swiper from 'swiper';

export default class HotelReviewManager {
    constructor() {
        this.ajaxManager = new AjaxManager();
        this.reviewImageModal = document.querySelector('[data-gallery-modal="review-images-modal"]');
        this.eventDelegation();
        this.loading = false;
    }

    eventDelegation() {
        $('body').on('click', '[data-show-hotel-reviews]', (event) => {
            if (this.loading) {
                return;
            }
            this.loading = true;
            let hotelId = $(event.currentTarget).data('show-hotel-reviews');
            let offset = $(event.currentTarget).attr('data-offset');
            let maxResults = $(event.currentTarget).data('max-results');
            const isThematic = $(event.currentTarget).attr('data-thematic');
            if (isThematic && myApp.groupThematicReviewManager) {
                myApp.groupThematicReviewManager.showReviews(hotelId, offset, maxResults, true).then(() => {
                    this.loading = false;
                });
            } else {
                this.showReviews(hotelId, offset, maxResults);
            }
        });

        document
            .querySelectorAll('[data-entity="reviews-with-photos"] [data-entity="swiper-slide"]')
            .forEach((element) => {
                element.addEventListener('click', (event) => {
                    if (!reviewImages || reviewImages.length === 0 || !imageReviews || imageReviews.length === 0) {
                        return;
                    }

                    const currentTarget = event.currentTarget;
                    const swiperImage = currentTarget.querySelector('[data-entity="swiper-image"]');
                    if (!swiperImage) {
                        return;
                    }

                    const index = parseInt(swiperImage.getAttribute('data-index'));
                    const imageIndex = parseInt(swiperImage.getAttribute('data-image-index'));
                    let userReview = this.createUserReview(index);

                    if (!userReview) {
                        return;
                    }

                    userReview = userReview._openReviewGallery(
                        imageIndex,
                        reviewImages.map((item) => item.image_url),
                        this.reviewImageModal,
                        currentTarget.classList.contains('swiper-slide-last'),
                    );

                    // Guardamos el color del alias para que siempre sea el mismo.
                    const userReviewId = userReview.getAttribute('user-review-id');
                    if (!userReviewId) {
                        return;
                    }

                    const currentUserReview = imageReviews.find((user) => user.id == userReviewId);
                    currentUserReview.avatarColor = userReview.avatarClasses;
                });
            });

        document.addEventListener('swiperSlideChanged', (e) => {
            if (!this.reviewImageModal || !this.reviewImageModal.shadowRoot) {
                return;
            }

            let userReviewContainer = this.reviewImageModal.shadowRoot.querySelector('user-review');
            if (!userReviewContainer) {
                return;
            }

            const imageGalleryList = userReviewContainer.imageGalleryList;
            if (!imageGalleryList) {
                return;
            }

            const activeIndex = e.detail;
            if (typeof activeIndex === 'undefined' || activeIndex === null || activeIndex < 0) {
                return;
            }

            const currentReview = reviewImages[activeIndex];
            if (!currentReview) {
                return;
            }

            // Guardamos el color del alias para que siempre sea el mismo.
            const currentUserReview = imageReviews.find((user) => user.id == currentReview.review_id);
            if (!currentUserReview.avatarColor) {
                currentUserReview.avatarColor = userReviewContainer.avatarClasses;
            }

            if (currentReview.review_id == userReviewContainer.getAttribute('user-review-id')) {
                return;
            }

            const userReview = userReviewContainer._createUserReviewGalleryList(
                currentUserReview,
            );

            userReviewContainer = userReviewContainer.parentElement;
            userReviewContainer.innerHTML = '';
            userReviewContainer.appendChild(userReview);

            const userReviewContent = userReview._shadowRoot;
            if (userReviewContent) {
                const userReviewAlias = userReviewContent.querySelector('[name="alias-summary"]');
                if (userReviewAlias && currentUserReview.avatarColor) {
                    userReviewAlias.className = currentUserReview.avatarColor;
                }
            }

            if (imageGalleryList) {
                imageGalleryList._addGalleryModalDarkMode();
            }
        });
    }

    showReviews(hotelId, offset, maxResults) {
        let url = myApp.config.routing.hotelReviewShow;
        let data = {
            hotelId: hotelId,
            offset: offset,
            maxResults: maxResults,
        };
        $('[data-show-hotel-reviews="' + hotelId + '"] [data-loader]').show();
        let callback = (reviewView) => {
            $('[data-show-hotel-reviews="' + hotelId + '"]').remove();
            $('[data-reviews-container="' + hotelId + '"]').append(reviewView);
            this.loading = false;
            document.querySelectorAll('user-review').forEach((element) => {
                element.setSwiper(Swiper);
            });
        };

        this.ajaxManager.ajaxCall('POST', url, data, callback);
    }

    createUserReview(index) {
        const reviewId = reviewImages[index].review_id;
        if (!reviewId) {
            return;
        }
        const genericUserReview = document.querySelector('user-review');
        const userReview = new UserReview();
        userReview.setSwiper(Swiper);

        const hotelInfo = genericUserReview.getAttribute('hotel-info');
        if (hotelInfo) {
            userReview.setAttribute('hotel-info', hotelInfo);
        }
        userReview.setAttribute('translations', genericUserReview.getAttribute('translations'));
        userReview.setAttribute('stylesheet', genericUserReview.getAttribute('stylesheet'));
        userReview.setAttribute(
            'user-review',
            JSON.stringify(imageReviews.find((imageReview) => imageReview.id === reviewId)),
        );
        userReview.setAttribute('user-review-id', reviewId);
        return userReview;
    }
}
