import AjaxManager from "../../../ajaxManager";
import InputValidator from "@web/vpt-assets/assets/js/utilities/validations/inputValidator";

export default class BookingFormValidator {
    constructor() {
        this.inputValidator = new InputValidator();
        this.passport = 'Passport';
        this.ajaxManager = new AjaxManager();
        this.forbiddenDni = ['00000000T', '11111111H', '22222222J', '33333333P', '44444444A', '55555555K', '66666666Q', '77777777B', '88888888Y', '99999999R'];
        this.validatedFields = {};
        this.bookingForm = $('#bookingForm');
        this.validator = this.getValidatorConfig();
        const formButton = document.querySelector('#submit-form');
        if (formButton) {
            formButton.classList.remove('disabled');
        }
        this.eventDelegation();
    };

    eventDelegation() {
        this.bookingForm.formValidation(this.validator)
            .on('keyup keypress', (e) => {
                const keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                }
            })
            .on('err.field.fv', (e, data) => {
                e.preventDefault();

                data.element.removeClass('border-success');

                if (data.element.hasClass('vpt-input') || data.element.hasClass('vpt-input-full') || data.element.hasClass('vpt-select') || data.element.hasClass('vpt-select-full')) {
                    data.element.addClass('border-error');
                }

                if ((data.field === 'mail' || data.field === 'dni') && this.validatedFields[data.field] !== $(data.element).val()) {
                    this.validatedFields[data.field] = $(data.element).val();
                    $(document).trigger('failedAttemptEvent', $('#bookingForm'));
                }
            })
            .on('success.field.fv', (e, data) => {
                data.element.removeClass('border-error');

                if (data.element.hasClass('vpt-input') || data.element.hasClass('vpt-input-full') || data.element.hasClass('vpt-select') || data.element.hasClass('vpt-select-full')) {
                    data.element.addClass('border-success');
                }
            })
            .on('err.form.fv', (e) => {
                e.preventDefault();
                let inputError = document.querySelector('.border-error');
                if (inputError) {
                    myApp.scrollManager.scrollToElement(inputError, 0, document.querySelector('#book-summary-mobile').clientHeight);
                }
            })
            .on('success.form.fv', (e) => {
                e.preventDefault();
                myApp.domAnimator.showElementWithDelay('#overlay-loading', 2000);
                $(e.target).data('formValidation').defaultSubmit();
            })
            .on('change', 'select', (e) => {
                this.bookingForm.data('formValidation').revalidateField(e.currentTarget.name);
            })
            .on('change', '[name="country"]', (e) => {
                this.bookingForm.data('formValidation').revalidateField('dni');
            })
            .on('change', '[name="client_country"]', (e) => {
                this.bookingForm.data('formValidation').revalidateField('client_nif');
            });

        myApp.formValidator.validateFieldsOnFocusOut('#bookingForm');
    };

    getValidatorConfig() {
        return {
            live: 'disabled',
            icon: myApp.formValidator.getFeedbackIcons(),
            fields: {
                titular_nombre: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.name']
                        },
                        regexp: {
                            regexp: myApp.formValidator.getOnlyCharactersRegExp(),
                            message: myApp.translator['forms.booking.errors.contactdata.invalid.characters']
                        }
                    }
                },
                titular_1: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.surname']
                        },
                        regexp: {
                            regexp: myApp.formValidator.getOnlyCharactersRegExp(),
                            message: myApp.translator['forms.booking.errors.contactdata.invalid.characters']
                        }
                    }
                },
                dni: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.dni']
                        },
                        callback: {
                            message: myApp.translator['forms.booking.errors.contactdata.invalid.dni'],
                            callback: (value) => {
                                if (document.querySelector('[name="country"]').value != 1) {
                                    return true;
                                }
                                return this.inputValidator.validateDniValue(value) || this.inputValidator.validateNieValue(value) || this.inputValidator.validateCifValue(value);
                            },
                            transformer: function ($field, validatorName, validator) {
                                var value = $field.val();
                                return value.toUpperCase();
                            }
                        }
                    }
                },
                mail: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.email']
                        },
                        regexp: {
                            transformer: ($field) => {
                                let trimmed = $field.val().toLowerCase().trim();
                                trimmed = myApp.form.dataTransformer.EmailCleaner.clear(trimmed);
                                $field.val(trimmed);
                                return trimmed;
                            },
                            regexp: myApp.formValidator.getEmailValidatorRegExp(),
                            message: myApp.translator['forms.booking.errors.contactdata.invalid.email']
                        }
                    }
                },
                phone1: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.phone']
                        },
                        callback: {
                            callback: (value, validator, $field) => {
                                if (value !== '') {
                                    return window.intlTelInputGlobals.getInstance($field.get(0)).isValidNumber();
                                }
                                return true;
                            },
                            message: myApp.translator['forms.booking.errors.contactdata.invalid.phone']
                        }
                    }
                },
                phone2: {
                    validators: {
                        callback: {
                            callback: (value, validator, $field) => {
                                if (value !== '') {
                                    return window.intlTelInputGlobals.getInstance($field.get(0)).isValidNumber();
                                }

                                return true;
                            },
                            message: myApp.translator['forms.booking.errors.contactdata.invalid.phone']
                        }
                    }
                },
                client: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoicename']
                        }
                    }
                },
                client_nif: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.dni']
                        },
                        callback: {
                            message: myApp.translator['forms.booking.errors.contactdata.invalid.dni'],
                            callback: (value) => {
                                if (document.querySelector('[name="client_country"]').value != 1) {
                                    return value.length > 0;
                                }
                                return this.inputValidator.validateDniValue(value) || this.inputValidator.validateNieValue(value) || this.inputValidator.validateCifValue(value);
                            },
                            transformer: function ($field, validatorName, validator) {
                                var value = $field.val();
                                return value.toUpperCase();
                            }
                        }
                    }
                },
                client_direccio: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoiceaddress']
                        }
                    }
                },
                client_postal: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoicepostalcode']
                        }
                    }
                },
                client_poblacio: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoicetown']
                        }
                    }
                },
                client_provincia: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoiceregion']
                        }
                    }
                },
                codi_pais: {
                    enabled: false,
                    icon: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoicecountry']
                        }
                    }
                },
                country: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.country']
                        }
                    }
                },
                client_country: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.country']
                        }
                    }
                },
                invoice_send_name: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoicename']
                        }
                    }
                },
                invoice_send_address: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoiceaddress']
                        }
                    }
                },
                invoice_send_city: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoicetown']
                        }
                    }
                },
                invoice_send_region: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoiceregion']
                        }
                    }
                },
                invoice_send_zip_code: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.invoicepostalcode']
                        }
                    }
                },
                invoice_send_country: {
                    validators: {
                        notEmpty: {
                            message: myApp.translator['forms.booking.errors.contactdata.empty.country']
                        }
                    }
                },
                invoice_format: {
                    enabled: false,
                    validators: {
                        notEmpty: {
                            message: myApp.translator['invoicing.requestinvoice.body.form.errors.format']
                        }
                    }
                }
            }
        };
    }
};
