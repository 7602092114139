import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils'

export default class IntTelephoneType {

    constructor()
    {
        this.featuredContries = ['gb', 'ie', 'fr', 'pt', 'de', 'nl', 'be', 'ch', 'es', 'ad'];
        this.defaultCountries = {
            1: 'es',
            2: 'es',
            3: 'gb',
            4: 'fr',
            5: 'pt',
            6: 'de',
            7: 'it'
        };
        this.eventDelegation();
    }

    eventDelegation() {
        this.setInitializeIntTelType(this.getClientCountry());
        this.copyTelToInputHidden();
        this.setFeaturedPrefix();
    };
    
    setInitializeIntTelType(defaultCountry) {
        this.intlTelInputs = [];
        $('[data-type="int-tel"]').each((index, intlTelInputElement) => {
            intlTelInput(intlTelInputElement, {
                preferredCountries: this.featuredContries,
                separateDialCode: true,
                initialCountry: defaultCountry,
                responsiveDropdown: true,
                utilsScript: utils
            });
            this.intlTelInputs.push(window.intlTelInputGlobals.getInstance(intlTelInputElement));

            if (intlTelInputElement.id) {
                const label = document.querySelector('label[for=' + intlTelInputElement.id + ']');
                const inputDivider = document.querySelector('.vpt-input-divider');
                if (label && inputDivider) {
                    inputDivider.style.setProperty("--divider-offset", `${parseInt(intlTelInputElement.style.paddingLeft) - 5}px`);
                }
                if (label) {
                    label.style.left = intlTelInputElement.style.paddingLeft;
                    intlTelInputElement.parentNode.appendChild(label);
                }

            }
        });
    };

    copyTelToInputHidden() {
        $("body").on("blur", "[data-type='int-tel']", (intlTelInputElement) => {
            // Copy full tel to input hidden
            const inputNumTarget = intlTelInputElement.target.getAttribute('data-target');
            if (inputNumTarget) {
                this._copyFullTel(intlTelInputElement, inputNumTarget);
                return;
            }

            // Copy dial code to input dial hidden
            const inputDialTarget = intlTelInputElement.target.getAttribute('data-dial-target');
            if (inputDialTarget) {
                this._copyDialCode(inputDialTarget);
            }
        });
    };

    _copyFullTel(intlTelInputElement, inputNumTarget) {
        let phoneWithPrefix = this.intlTelInputs[inputNumTarget].getNumber();
        $('#' + $(intlTelInputElement.currentTarget).attr('name') + '[data-target='+ inputNumTarget +']').val(phoneWithPrefix.replace('+', ''));
    }

    _copyDialCode(inputDialTarget) {
        const dialCode = this.intlTelInputs[0].selectedDialCode;
        if (dialCode && dialCode.textContent) {
            $('#'+inputDialTarget).val(dialCode.textContent.replace('+', ''));
        }
    }

    getClientCountry() {
        let countryElem = $("input[name=clientcountrycode]").filter((index, elem) => $(elem).val());
        let country = countryElem.val();
        if (country !== undefined) {
            return country.toLowerCase();
        } else {
            let languageElem = $("input[name=idioma]").filter((index, elem) => $(elem).val());
            let language = parseInt(languageElem.val());
            if (language) {
                return this.defaultCountries[language];
            }
        }
        return this.defaultCountries[1];
    }

    setFeaturedPrefix() {
        const countries = intlTelInputGlobals.getCountryData();

        this.intlTelInputs.forEach( intlTelInput => {
            const prefix = intlTelInput.selectedCountryData.dialCode;
            if (!prefix) {
                return;
            }

            let inputCountry = countries.find(country => country.dialCode === prefix && this.featuredContries.includes(country.iso2));

            if (!inputCountry) {
                inputCountry = countries.find(country => country.dialCode === prefix);
            }

            if (inputCountry) {
                intlTelInput.setCountry(inputCountry.iso2)
            }

            //Copy dial code to input dial hidden
            const inputDialTarget = intlTelInput.telInput.dataset.dialTarget
            if (inputDialTarget) {
                this._copyDialCode(inputDialTarget);
            }
        })
    }
};
