export default class GroupThematicReviewManager {
    constructor() {
        this.loaded = false;
    }

    async showReviews(groupThematicId, offset, maxResults, loadThematicRatingSummary) {
        if (this.loaded && offset == 0) return;
        let data = {
            surveyThematicId: groupThematicId,
            offset: offset,
            maxResults: maxResults,
            loadThematicRatingSummary: loadThematicRatingSummary
        }

        $('[data-show-hotel-reviews="' + groupThematicId + '"][data-thematic] [data-loader]').show();

        const response = await fetch(myApp.config.routing.groupThematicReviewShow, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(data)
        }).then(response => response.json()).then((response) => {
            $('[data-show-hotel-reviews="' + groupThematicId + '"][data-thematic]').remove();
            $('[data-reviews-container="' + groupThematicId + '"]').append(response.content);
            this.loaded = true;
        });

        return true;
    }
}
