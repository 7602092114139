import AjaxManager from "../ajaxManager";

export default class ActivityReviewManager {
    constructor() {
        this.ajaxManager = new AjaxManager();
        this.eventDelegation();

    }

    eventDelegation() {
        $('body').on('click', '[data-show-activity-reviews]', (event) => {
            let activityId = $(event.currentTarget).data('show-activity-reviews');
            let offset = $(event.currentTarget).data('offset');
            let maxResults = $(event.currentTarget).data('max-results');
            this.showReviews(activityId, offset, maxResults);
        });
    }

    showReviews(activityId, offset, maxResults) {
        let url = myApp.config.routing.activityReviewShow;
        let data = {
            activityId: activityId,
            offset: offset,
            maxResults: maxResults
        };
        $('[data-show-activity-reviews="' + activityId + '"] [data-loader]').show();
        let callback = (reviewView) => {
            $('[data-show-activity-reviews="' + activityId + '"]').remove();
            $('[data-reviews-container="' + activityId + '"]').append(reviewView);
        }

        this.ajaxManager.ajaxCall('POST', url, data, callback);
    }
}


