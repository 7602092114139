import MyAppConfig from "./myApp.config";
import ModalScrollManager from "./services/modalScrollManager";
import DatepickerPrinter from "./services/dom/datepicker/datepickerPrinter";
import DatepickerValidator from "./services/forms/validator/datepickerValidator";
import UserManager from "./services/userManager";
import BrowserStorageService from "./services/browserStorageService";
import DeviceDetector from "./services/deviceDetector";
import UrlManager from "./services/urlManager";
import ScrollManager from "./services/scrollManager";
import NotificationManager from "./services/notificationManager";
import DomAnimator from "./services/domAnimator";
import CholloManager from "./services/entity/cholloManager";
import TextTransformer from "./services/dom/utilities/textTransformer";
import CollapseManager from "./services/dom/utilities/collapseManager";
import PopoverManager from "./services/dom/utilities/popoverManager";
import ReviewsManager from "./services/reviewsManager";
import DateFormatter from "./services/dateFormatter";
import DomManager from "./services/dom/domManager";
import TranslatorService from "./services/translator/translatorService";
import AffixManager from "./services/affixManager";
import BlockRealignmentService from "./services/dom/blockRealignmentService";
import FormInputMapper from "./services/forms/formInputMapper";
import CharacterInputHandler from "./services/dom/characterHandler";
import FormObserver from "./services/forms/formObserver";
import FormFileManager from "./services/forms/formFileManager";
import VisibilityToggler from "./services/dom/visibilityToggler";
import FormPrinter from "./services/forms/formPrinter";
import FormValidator from "./services/forms/formValidator";
import FormGroupValidator from "./services/forms/formGroupValidator";
import FormSubmitterManager from "./services/forms/formSubmitter";
import NewsletterSubscriptionValidator from "./services/forms/validator/newsletterSubscriptionValidator";
import ClientReplyFormValidator from "./services/forms/validator/contact/clientReplyFormValidator";
import DropdownManager from "./services/dom/utilities/dropdownManager";
import SendBudgetFormValidator from "./services/forms/validator/book/sendBudgetFormValidator";
import BookingContactFormValidator from "./services/forms/validator/contact/bookingContactFormValidator";
import ContactFormValidator from "./services/forms/validator/contact/contactFormValidator";
import SubscribeManager from "./services/subscribeManager";
import BrowserHistoryManager from "@web/vpt-assets/assets/js/utilities/dom/browserHistoryManager";
import OverlayMenuToggler from "./services/menu/overlayMenuToggler";
import EmailCleaner from "./services/forms/emailCleaner";
import BookingDatepickerConfigurator from "./services/book/bookingDatepickerConfigurator";
import VatCleaner from "./services/forms/vatCleaner";
import BookingFormValidator from "./services/forms/validator/book/bookingFormValidator";
import RequestInvoiceValidator from "./services/forms/validator/invoicing/requestInvoiceValidator";
import Lightbox from "./services/lightbox/lightbox";
import HotelCustomMapBuilder from "./services/customMap/hotelCustomMapBuilder";
import MenuManager from "./services/menu/menuManager";
import GroupCardManager from "./services/book/groupCardManager";
import HotelReviewManager from "./services/hotelReview/hotelReviewManager";
import activityReviewManager from "./services/activityReview/activityReviewManager";
import CollapseManagerNew from "@web/vpt-assets/assets/js/utilities/collapseManager";
import CustomModalManager from "@web/vpt-assets/assets/js/utilities/customModalManager";
import CustomDropdownManager from "@web/vpt-assets/assets/js/utilities/dropdownManager";
import IntTelephoneType from "./services/forms/type/intTelephoneType";
import ServicesController from "@web/vpt-assets/assets/js/controller/servicesController";
import ExclusiveDialogModal from "./services/user/exclusiveDialogModal";
import UserLoginRegisterModalHandler
    from "@web/vpt-assets/assets/js/modules/userLoginRegister/userLoginRegisterModalHandler";
import TrackersManager from "./services/trackers/trackersManager";
import GroupThematicReviewManager from "./services/group/groupThematicReviewManager";
import GroupVideoManager from "./services/group/GroupVideoManager";

global.myApp = {
    initializer: {},
    form: {
        book: {},
        validator: {},
        dataTransformer: {}
    },
    controller: {
        subscribeController: {},
        step1Controller: {},
        step2Controller: {},
        step3Controller: {}
    },
    translator: {},
    mapBuilder: {},
    book: {},
    user: {},
    menu: {},
    filterer: {},
    components: {
        time: {}
    },
    tracking: {
        gtm: {},
        ga: {}
    },
    lightboxList: [],
    config: [],
};

$(function () {
    myApp.initializer.loadListenerSystem();
    myApp.initializer.iniServices();
    myApp.initializer.initializeApp();
});

myApp.initializer.iniServices = function () {
    myApp.myAppConfig = new MyAppConfig();
    myApp.modalScrollManager = new ModalScrollManager();
    myApp.datepickerPrinter = new DatepickerPrinter();
    myApp.datepickerValidator = new DatepickerValidator();
    myApp.bookingDatepickerConfigurator = new BookingDatepickerConfigurator();
    myApp.browserStorageService = new BrowserStorageService();
    myApp.userManager = new UserManager();
    myApp.deviceDetector = new DeviceDetector();
    myApp.urlManager = new UrlManager();
    myApp.scrollManager = new ScrollManager();
    myApp.notificationManager = new NotificationManager();
    myApp.domAnimator = new DomAnimator();
    myApp.cholloManager = new CholloManager();
    myApp.groupThematicReviewManager = new GroupThematicReviewManager();
    myApp.textTransformer = new TextTransformer();
    myApp.collapseManager = new CollapseManager();
    myApp.popoverManager = new PopoverManager();
    myApp.reviewsManager = new ReviewsManager();
    myApp.dateFormatter = new DateFormatter();
    myApp.domManager = new DomManager();
    myApp.translatorService = new TranslatorService();
    myApp.exclusiveDialogModal = new ExclusiveDialogModal();
    myApp.affixManager = new AffixManager();
    myApp.blockRealignmentService = new BlockRealignmentService();
    myApp.formInputMapper = new FormInputMapper();
    myApp.characterInputHandler = new CharacterInputHandler();
    myApp.formFileManager = new FormFileManager();
    myApp.visibilityToggler = new VisibilityToggler();
    myApp.form.observer = new FormObserver();
    myApp.form.formPrinter = new FormPrinter();
    myApp.formValidator = new FormValidator();
    myApp.formGroupValidator = new FormGroupValidator();
    myApp.formSubmitterManager = new FormSubmitterManager();
    myApp.form.dataTransformer.vatCleaner = new VatCleaner();
    myApp.form.validator.newsletterSubscriptionValidator = new NewsletterSubscriptionValidator();
    myApp.form.validator.clientReplyFormValidator = new ClientReplyFormValidator();
    myApp.form.validator.sendBudgetFormValidator = new SendBudgetFormValidator();
    myApp.form.validator.bookingContactFormValidator = new BookingContactFormValidator();
    myApp.form.validator.contactFormValidator = new ContactFormValidator();
    myApp.form.validator.bookingFormValidator = new BookingFormValidator();
    myApp.form.validator.requestInvoiceValidator = new RequestInvoiceValidator();
    myApp.dropdownManager = new DropdownManager();
    myApp.subscribeManager = new SubscribeManager();
    const servicesController = new ServicesController();
    myApp.browserHistoryManager = servicesController.getService(BrowserHistoryManager.className);
    myApp.menu.overlayMenuToggler = new OverlayMenuToggler();
    myApp.form.dataTransformer.EmailCleaner = new EmailCleaner();
    myApp.lightbox = new Lightbox();
    myApp.user.loginManager = new UserLoginRegisterModalHandler();
    myApp.menu.manager = new MenuManager();
    myApp.groupCardManager = new GroupCardManager();
    myApp.hotelReviewManager = new HotelReviewManager();
    myApp.activityReviewManager = new activityReviewManager();
    myApp.collapseManagerNew = new CollapseManagerNew();
    myApp.customModalManager = servicesController.getService(CustomModalManager.className, {'closeImmediately': myApp.config.parameters.isIOSApp});
    myApp.intTelephoneType = new IntTelephoneType();
    new TrackersManager();
    new GroupVideoManager();

    $(document).ready(function () {
        myApp.hotelCustomMapBuilder = new HotelCustomMapBuilder();
    });
    myApp.customDropdownManagerNew = new CustomDropdownManager();
};


myApp.initializer.initializeApp = function () {
    myApp.config.rootPath = myApp.myAppConfig.rootPath;
    myApp.config.cdnPath = myApp.myAppConfig.cdnPath;
    myApp.config.analytics = myApp.myAppConfig.analytics;

    myApp.initializer.eventDelegation();
};

myApp.initializer.eventDelegation = function () {

    if ($('[data-field="bottom-fixed"]').is(':visible') && !$('.scroll-top-wrapper').hasClass('bottom-higher')) {
        $('.scroll-top-wrapper').addClass('bottom-higher');
    }

    $("#info_chollo_modal").on('custom-modal-shown', function () {
        $(".scroll-top-wrapper").removeClass('show-scroll');
        myApp.scrollManager.scrollToElement("#moreInfoModalContent-m");
    });

    $("#info_chollo_modal").on('custom-modal-hidden', function () {
        $(this).find('[data-google-infowindow]').remove();
    });

    $("body").on("click", "[data-action='autodestroy']", function () {
        $(this).remove();
    });

    if ($('[data-field="button-fixed"]').is(":visible")) {
        $("body").addClass('bottom-nav');
    }
};

myApp.initializer.arrowToTop = function () {
    var arrowScrollTop = $('.scroll-top-wrapper');
    if (arrowScrollTop.length > 0) {
        if ($(document).scrollTop() > 300) {
            arrowScrollTop.addClass('show-scroll');
            if (arrowScrollTop.offset().top > $('.footer-bottom').offset().top) {
                $('.scroll-top-wrapper').addClass('on-footer');
            } else {
                arrowScrollTop.removeClass('on-footer');
            }
        } else {
            arrowScrollTop.removeClass('show-scroll');
            arrowScrollTop.removeClass('on-footer');
        }
    }
};

myApp.initializer.loadListenerSystem = function () {
    $.fn.observe = function (eventName, callback) {
        return this.each(function () {
            var el = this;
            $(document).on(eventName, function () {
                callback.apply(el, arguments);
            });
        });
    };
};

